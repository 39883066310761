import group from './group';

import assets from './assets';

import email from './email';
import landingpage from './landingpage';

import youtube from './video/youtube';

import meta from './social/meta';
import tiktok from './social/tiktok';
import snapchat from './social/snapchat';
import linkedin from './social/linkedin';
import x from './social/x';
import reddit from './social/reddit';
import pinterest from './social/pinterest';
import multiSocial from './social/multi-social';

import cm360 from './display/campaignmanager';

import feed from './feed';

import creatives from './creative';
import customBricks from './custom-bricks';

import peachconnect from './convergedtv/peachconnect';

export default {
    // group
    group: group,

    // assets
    asset_set: assets.assetSet,
    single_asset: assets.singleAsset,

    // creative
    creative_all: creatives.all,
    creative_image: creatives.image,
    creative_video: creatives.video,
    creative_displayad: creatives.displayad,
    creative_pdf: creatives.pdf,

    // email
    email: email,

    // landing page
    landingpage: landingpage,

    // video
    youtube_post: youtube.post,

    // socials
    meta_campaign: meta.campaign,
    meta_adset: meta.adset,
    meta_ad: meta.ad,

    tiktok_campaign: tiktok.campaign,
    tiktok_adgroup: tiktok.adgroup,
    tiktok_ad: tiktok.ad,

    snapchat_campaign: snapchat.campaign,
    snapchat_adsquad: snapchat.adsquad,
    snapchat_ad: snapchat.ad,

    linkedin_campaignGroup: linkedin.campaignGroup,
    linkedin_campaign: linkedin.campaign,
    linkedin_ad: linkedin.ad,

    x_campaign: x.campaign,
    x_adset: x.adset,
    x_ad: x.ad,

    reddit_campaign: reddit.campaign,
    reddit_adgroup: reddit.adgroup,
    reddit_ad: reddit.ad,

    pinterest_campaign: pinterest.campaign,
    pinterest_adgroup: pinterest.adgroup,
    pinterest_ad: pinterest.ad,

    multiSocial_campaign: multiSocial.campaign,
    multiSocial_adset: multiSocial.adset,
    multiSocial_ad: multiSocial.ad,

    // Display
    cm360_campaign: cm360.campaign,
    cm360_placementGroup: cm360.placementGroup,
    cm360_placement: cm360.placement,
    cm360_ad: cm360.ad,

    // feed
    feed_custom: feed.customFeed,

    // Custom bricks
    creative_format: customBricks.creativeFormat,

    // ConvergedTV
    peachconnect_campaign: peachconnect.campaign,
    peachconnect_ad: peachconnect.ad
};
