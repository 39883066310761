import { PlacementsSection } from 'components/bricks/types/placement.type';
import Translation from 'components/data/Translation';
import linkedinFeed from '../../../../../components/shared/components/placements-block/images/linkedin-feed.png';

const placements: PlacementsSection[] = [
    {
        key: 'feed',
        label: Translation.get('adSetup.placements.feed', 'bricks'),
        children: [
            {
                recommendedHeight: 1,
                minHeight: 600,
                recommendedWidth: 1,
                minWidth: 600,
                type: 'feed',
                key: 'linkedin-feed',
                label: 'Linkedin Feed',
                platform: 'linkedin',
                channel: 'linkedin',
                frameWidth: 340,
                multiFrame: true,
                icon: 'home',
                description: Translation.get('adSetup.placements.linkedinFeedDescription', 'bricks'),
                previewImage: linkedinFeed
            }
        ]
    }
];

export default placements;
