import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import { ValidationObject } from 'components/bricks/types/validator.type';

// Ad setup data from the brick object
const brickAdSetup = 'brick.data?.adSetup?';

// Ad setup is of type 'single'
const isSingleAdSetup = `${brickAdSetup}.type === 'single'`;

// Targeting placements from the parent settings
const parentSettingsPlacementTargeting = `parent?.data?.settings?.targeting?.placements?`;

const validators: ValidationObject = {
    validators: [
        {
            condition: `!${brickAdSetup}`,
            message: 'There is no data in ad setup',
            inputIdentifiers: ['linkedin_ad-ad_setup'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0] && !${brickAdSetup}.items[0].creativeIds?.length`,
            message: 'There are no creatives',
            inputIdentifiers: ['linkedin_ad-ad_setup-creative_input'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `(!${parentSettingsPlacementTargeting}.length || ${parentSettingsPlacementTargeting}.includes("linkedin-feed")) && ${isSingleAdSetup} && ${brickAdSetup}.items?.[0] && (!${brickAdSetup}.items[0].creativeIds || !${brickAdSetup}.items[0].creativeIds?.length || !${brickAdSetup}.items[0].placements["linkedin-feed"])`,
            message: 'Linkedin Feed has not assigned creative',
            inputIdentifiers: ['linkedin_ad-ad_setup-placements-linkedin-feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'commentary'),
            message: 'Introductory text is required',
            inputIdentifiers: ['linkedin_ad-ad_setup-commentary'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Introductory text is required'
                }
            ]
        },

        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.commentary.length > 600`,
            message: 'Introductory text must be 600 characters or less',
            inputIdentifiers: ['linkedin_ad-ad_setup-commentary'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 600,
                    message: 'Introductory text must be 600 characters or less'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'title'),
            message: 'Title is required',
            inputIdentifiers: ['linkedin_ad-ad_setup-title'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },

        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.title.length > 200`,
            message: 'Title length should be 200 characters or less',
            inputIdentifiers: ['linkedin_ad-ad_setup-title'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 200,
                    message: 'Title length must be 200 characters or less'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'description'),
            message: 'Description is required',
            inputIdentifiers: ['linkedin_ad-ad_setup-description'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },

        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.description.length > 300`,
            message: 'Description length must be 300 characters or less',
            inputIdentifiers: ['linkedin_ad-ad_setup-description'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 300,
                    message: 'Description length must be 300 characters or less'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'source'),
            message: 'Link is required',
            inputIdentifiers: ['linkedin_ad-ad_setup-source'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.source && !${brickAdSetup}.items[0].source?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link is not a valid URL',
            inputIdentifiers: ['linkedin_ad-ad_setup-source'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.source?.${ValidateHelpers.getRegexMatch('linkedinSourceRestrictionChars')}`,

            message: "Link Url can't contain < > # % { } [ ] these characters",
            inputIdentifiers: ['linkedin_ad-ad_setup-source'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[<>#%{}[\]\\]/g }],
                    severity: 'error',
                    message: "Link Url can't contain < > # % { } [ ] these characters"
                }
            ]
        }
    ]
};

export default validators;
