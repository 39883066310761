import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import adSetup from 'components/bricks/components/shared/components/ad-setup';
import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import icon from './icon.svg';
import defaultData from './default-data';
import adSetupSingleFrame from './ad-setup-single-frame';
import placements from '../data/placements';
import validators from './validators';
import { channels } from '../data/channels';

export default {
    settings,
    setup,
    icon,
    defaultData,
    briefing,
    adSetup,
    adSetupSingleFrame,
    validators,
    placements,
    output,
    channels
};
