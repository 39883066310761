import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                fixedModel: true,
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'account',
                identifier: 'linkedin_campaign-account',
                label: 'Ad account id input',
                metadataType: 'linkedInAdAccount',
                config: {
                    q: 'search'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Objective',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'objectiveType',
                label: 'Objective type',
                identifier: 'linkedin_campaign-objective_type',
                options: {
                    BRAND_AWARENESS: 'Brand awareness',
                    ENGAGEMENT: 'Engagement',
                    JOB_APPLICANTS: 'Job applicants',
                    LEAD_GENERATION: 'Lead generation',
                    WEBSITE_CONVERSIONS: 'Website conversions',
                    WEBSITE_VISITS: 'Website visits',
                    VIDEO_VIEWS: 'Video veiws'
                }
            }
        ]
    },
    {
        title: 'Audience',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'locale.iso',
                label: 'Languages',
                identifier: 'linkedin_campaign-locale-iso',
                metadataType: 'linkedInTargetingEntities',
                config: {
                    q: 'adTargetingFacet',
                    facet: 'urn:li:adTargetingFacet:interfaceLocales',
                    queryVersion: 'QUERY_USES_URNS'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'targetingCriteria.locations',
                identifier: 'linkedin_campaign-targeting-locations',
                label: 'Locations',
                metadataType: 'linkedInTargetingEntities',
                config: {
                    q: 'typeahead',
                    queryVersion: 'QUERY_USES_URNS',
                    facet: 'urn:li:adTargetingFacet:locations'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'targetingCriteria.interests',
                identifier: 'linkedin_campaign-targeting-interests',
                label: 'Interests',
                metadataType: 'linkedInTargetingEntities',
                config: {
                    q: 'typeahead',
                    queryVersion: 'QUERY_USES_URNS',
                    facet: 'urn:li:adTargetingFacet:interests'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'targetingCriteria.schools',
                identifier: 'linkedin_campaign-targeting-schools',
                label: 'Schools',
                metadataType: 'linkedInTargetingEntities',
                config: {
                    q: 'typeahead',
                    queryVersion: 'QUERY_USES_URNS',
                    facet: 'urn:li:adTargetingFacet:schools'
                },
                useValueObject: false
            },

            {
                type: 'buttonGroup',
                model: 'targetingCriteria.genders',
                identifier: 'linkedin_campaign-targeting-genders',
                label: 'Gender',
                options: {
                    GENDER_UNLIMITED: 'All',
                    'urn:li:gender:MALE': 'Male',
                    'urn:li:gender:FEMALE': 'Female'
                }
            },
            {
                type: 'checkboxList',
                model: 'targetingCriteria.ageRanges',
                identifier: 'linkedin_campaign-targeting-age_rangers',
                label: 'Age ranges',
                selectType: 'multiple',
                options: {
                    'urn:li:ageRange:(13,17)': '13-17',
                    'urn:li:ageRange:(18_24)': '18:24',
                    'urn:li:ageRange:(25_34)': '25-34',
                    'urn:li:ageRange:(35_54)': '35-54',
                    'urn:li:ageRange:(55,2147483647)': '55+'
                }
            }
        ]
    },
    {
        title: 'Ad format',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'radioList',
                model: 'type',
                label: 'Type',
                identifier: 'linkedin_campaign-type',
                options: [
                    {
                        key: 'STANDARD_UPDATE',
                        value: 'Standart update'
                    },
                    {
                        key: 'CAROUSEL',
                        value: 'Carousel'
                    },
                    {
                        key: 'SINGLE_VIDEO',
                        value: 'Video'
                    },
                    {
                        key: 'TEXT_AD',
                        value: 'Text'
                    },
                    {
                        key: 'FOLLOW_COMPANY',
                        value: 'Follower'
                    },
                    {
                        key: 'SPONSORED_MESSAGE',
                        value: 'Sponsored message'
                    }
                ]
            }
        ]
    },
    {
        title: 'Budget and schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'budget_mode',
                label: 'Budget mode',
                identifier: 'linkedin_campaign-budget_mode',
                tooltip: 'Type of the budget',
                options: {
                    DAILY: 'DAILY',
                    LIFETIME: 'LIFETIME'
                }
            },
            {
                type: 'number',
                tooltip: 'Daily campaign budget',
                identifier: 'linkedin_campaign-budget-amount',
                model: 'budget.amount',
                label: 'Budget'
            },
            {
                type: 'select',
                model: 'budget.currencyCode',
                label: 'Budget currency code',
                identifier: 'linkedin_campaign-budget-currency_code',
                condition: 'blockData.delivery_strategy==="start_end_budget"',
                options: {
                    BGN: 'Bulgarial lev',
                    EUR: 'Euro',
                    USD: 'Dollar'
                }
            },
            {
                type: 'radioList',
                model: 'delivery_strategy',
                identifier: 'linkedin_campaign-delivery_strategy',
                label: 'Delivery',
                options: [
                    {
                        key: 'continuously',
                        value: 'Run continuously from a start date'
                    },
                    {
                        key: 'start_end',
                        value: 'Set a start and end date'
                    }
                ]
            },
            {
                type: 'date',
                model: 'runSchedule.start',
                identifier: 'linkedin_campaign-run_schedule-start',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'runSchedule.end',
                identifier: 'linkedin_campaign-run_schedule-end',
                condition: 'blockData.delivery_strategy!=="continuously"',
                label: 'End date'
            },
            {
                type: 'select',
                model: 'optimizationTargetType',
                identifier: 'linkedin_campaign-optimization_target_type',
                label: 'Optimization goal',
                options: {
                    NONE: 'None',
                    ENHANCED_CONVERSION: 'Enhanced conversion',
                    MAX_IMPRESSION: 'Max impression',
                    MAX_CLICK: 'Max click',
                    MAX_CONVERSION: 'Max conversion',
                    MAX_VIDEO_VIEW: 'Max video view',
                    MAX_LEAD: 'Max lead',
                    MAX_REACH: 'Max reach',
                    TARGET_COST_PER_CLICK: 'Target cost per click',
                    TARGET_COST_PER_IMPRESSION: 'Target cost per impression',
                    TARGET_COST_PER_VIDEO_VIEW: 'Target cost per video view',
                    CAP_COST_AND_MAXIMIZE_CLICKS: 'Cap cost and maximize clicks',
                    CAP_COST_AND_MAXIMIZE_IMPRESSIONS: 'Cap cost and maximize impressions',
                    CAP_COST_AND_MAXIMIZE_VIDEO_VIEWS: 'Cap cost and maximize video views',
                    CAP_COST_AND_MAXIMIZE_LEADS: 'Cap cost and maximize leads'
                }
            }
        ]
    },
    {
        title: 'Conversion tracking (optional)',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'conversion',
                identifier: 'linkedin_campaign-conversion',
                label: 'Conversions',
                metadataType: 'linkedInConversion',
                config: {
                    q: 'account',
                    account: 'urn:li:sponsoredAccount:511141784'
                },
                useValueObject: false
            }
        ]
    }
    //TODO: To discuss
    // {
    //     title: 'Publish',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     opened: true,
    //     items: [
    //         {
    //             type: 'text',
    //             model: 'settings.publish.channels.linkedin.set.name',
    //             label: 'Publish set name',
    //             placeholder: "e.g. 'Backtoschool2020 set'",
    //             helperText: 'The publish name is automatically added to the naming when publishing, at set level.',
    //             useValueObject: false
    //         },

    //         {
    //             type: 'select',
    //             model: 'settings.publish.channels.linkedin.set.costType',
    //             label: 'Cost type',
    //             tooltip: 'CPM - Cost per thousand advertising impressions CPC - Cost per individual click on the associated link',
    //             options: {
    //                 CPC: 'Cost per click',
    //                 CPM: 'Cost per thousand'
    //             }
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Campaign daily budget amount',
    //             model: 'settings.publish.channels.linkedin.set.dailyBudget.amount',
    //             label: 'Daily budget amount'
    //         },
    //         {
    //             type: 'select',
    //             model: 'settings.publish.channels.linkedin.set.dailyBudget.currencyCode',
    //             label: 'Daily budget currency code',
    //             options: {
    //                 BGN: 'Bulgarial lev',
    //                 EUR: 'Euro',
    //                 USD: 'Dollar'
    //             }
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Campaign total budget amount',
    //             model: 'settings.publish.channels.linkedin.set.totalBudget.amount',
    //             label: 'Total budget amount'
    //         },
    //         {
    //             type: 'select',
    //             model: 'settings.publish.channels.linkedin.set.totalBudget.currencyCode',
    //             label: 'Total budget currency code',
    //             options: {
    //                 BGN: 'Bulgarial lev',
    //                 EUR: 'Euro',
    //                 USD: 'Dollar'
    //             }
    //         },
    //         {
    //             type: 'number',
    //             tooltip:
    //                 'This value is used as one of the following: amount to bid (for manual bidding), amount which is the target cost (for target cost bidding) per click, impression, or other event depending on the pricing model, or cost cap (for cost cap bidding). ',
    //             model: 'settings.publish.channels.linkedin.set.unitCost.amount',
    //             label: 'Unit cost amount'
    //         },
    //         {
    //             type: 'select',
    //             model: 'settings.publish.channels.linkedin.set.unitCost.currencyCode',
    //             label: 'Unit cost currency code',
    //             options: {
    //                 BGN: 'Bulgarial lev',
    //                 EUR: 'Euro',
    //                 USD: 'Dollar'
    //             }
    //         },
    //         {
    //             type: 'buttonGroup',
    //             model: 'settings.publish.channels.linkedin.set.creativeSelection',
    //             label: 'Creative selection',
    //             defaultValue: 'OPTIMIZED',

    //             options: {
    //                 OPTIMIZED: 'Optimized',
    //                 ROUND_ROBIN: 'Round robin'
    //             }
    //         },
    //         {
    //             type: 'buttonGroup',
    //             model: 'settings.publish.channels.linkedin.set.audienceExpansionEnabled',
    //             label: 'Is audience expansion enabled',
    //             defaultValue: 'false',
    //             tooltip: 'Enable Audience Expansion for the campaign provides query expansion for certain targeting criteria.',
    //             options: {
    //                 false: 'False',
    //                 true: 'True'
    //             }
    //         },
    //         {
    //             type: 'buttonGroup',
    //             model: 'settings.publish.channels.linkedin.set.offsiteDeliveryEnabled',
    //             label: 'Is offsite delivery enabled',
    //             defaultValue: 'false',
    //             tooltip:
    //                 'Allows your campaign to be served on the LinkedIn Audience Network to extend the reach of the campaign by delivering ads beyond the LinkedIn feed to members on third-party apps and sites. There is no default set.',
    //             options: {
    //                 false: 'False',
    //                 true: 'True'
    //             }
    //         },
    //         {
    //             type: 'buttonGroup',
    //             model: 'settings.publish.channels.linkedin.set.type',
    //             label: 'Campaign type',

    //             options: {
    //                 TEXT_AD: 'Text ad',
    //                 SPONSORED_UPDATES: 'Sponsored updates',
    //                 SPONSORED_INMAILS: 'Sponsored inmails',
    //                 DYNAMIC: 'Dynamic'
    //             }
    //         },

    //         {
    //             type: 'number',
    //             tooltip: 'Bid amount',
    //             model: 'settings.publish.channels.linkedin.set.bid.amount',
    //             label: 'Bid amount'
    //         },
    //         {
    //             type: 'text',
    //             model: 'settings.publish.channels.linkedin.set.pacingStrategy',
    //             label: 'Pacing strategy',
    //             defaultValue: 'LIFETIME',
    //             disabled: true,
    //             useValueObject: false
    //         },

    //         {
    //             type: 'date',
    //             model: 'settings.publish.channels.linkeding.set.runSchedule.start',
    //             label: 'Start date'
    //         },
    //         {
    //             type: 'date',
    //             model: 'settings.publish.channels.linkeding.set.runSchedule.end',
    //             condition: "campaign.bricks.settings.publish.channels.linkedin.set.budget.type!=='LIFETIME'",
    //             label: 'End date'
    //         },
    //         {
    //             title: 'Targeting',
    //             description: 'Divides targeting',
    //             type: 'divider',
    //             label: 'Targeting',
    //             itemType: 'input',
    //             key: 'ea6ad26d-9d0d-4f6c-b4a9-1e33c41f9218',
    //             model: 'settings.general.publish.divider'
    //         },
    //         {
    //             type: 'autocomplete',
    //             inputType: 'taglist',
    //             model: 'settings.publish.channels.linkedin.set.targeting.locale.country',
    //             label: 'Countries',
    //             options: ['Bulgaria', 'Netherlands', 'United Kingdom', 'Belgium', 'Turkey']
    //         },
    //         {
    //             type: 'autocomplete',
    //             inputType: 'taglist',
    //             model: 'settings.publish.channels.linkedin.set.targeting.locale.language',
    //             label: 'Languages',
    //             options: ['Bulgarian', 'Dutch', 'English', 'French', 'Turkish']
    //         }
    //     ]
    // }
];

export default settings;
