import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Translation from 'components/data/Translation';
import { FieldSetCopy } from 'components/input/Copy';
import CreativeSelector from 'components/input/CreativeSelector';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import { withResources } from 'components/data/Resources';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import AdSetupInputWrapper from 'components/bricks/components/shared/components/ad-setup/components/ad-setup-input-wrapper';
import brickSetup from './setup';

interface Props {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;

    linkedin_cta: {
        [key: string]: string;
    };
}

const SingleFrame: React.FC<Props> = ({ handleSelectCreatives, linkedin_cta }) => {
    const { adSetup, creatives, updateAdSetupProperty } = useAdSetupContext();
    const linkedinAdSetup: any = adSetup;

    const inputValidators = useMemo(() => {
        return {
            commentary: ValidateHelpers.getInputValidators('linkedin_ad', `linkedin_ad-ad_setup-commentary`),
            title: ValidateHelpers.getInputValidators('linkedin_ad', `linkedin_ad-ad_setup-title`),
            description: ValidateHelpers.getInputValidators('linkedin_ad', `linkedin_ad-ad_setup-description`),
            source: ValidateHelpers.getInputValidators('linkedin_ad', `linkedin_ad-ad_setup-source`)
        };
    }, []);

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <Accordion square title={Translation.get('adSetup.main', 'bricks')}>
            <div>
                <CreativeSelector
                    onMutation={(creatives) => handleSelectCreatives(creatives)}
                    multiple={multiple}
                    canEditCreative={canEditCreative}
                    value={creatives}
                    selectors={selectors}
                    templateTypes={templateTypes}
                />
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.linkedinAd.commentary', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('commentary', newValue, true)}
                        value={linkedinAdSetup?.items?.[0].commentary}
                        validators={inputValidators.commentary}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.linkedinAd.title', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('title', newValue, true)}
                        value={linkedinAdSetup?.items?.[0].title}
                        validators={inputValidators.title}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.linkedinAd.description', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('description', newValue, true)}
                        value={linkedinAdSetup?.items?.[0].description}
                        validators={inputValidators.description}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetSelectInput
                        label={Translation.get('adSetup.linkedinAd.callToAction', 'bricks')}
                        useValueObject={false}
                        value={linkedinAdSetup.cta && linkedin_cta[linkedinAdSetup.cta] ? linkedinAdSetup.cta : ''}
                        onMutation={(newValue) => updateAdSetupProperty('cta', newValue)}
                        options={linkedin_cta}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.linkedinAd.source', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('source', newValue, true)}
                        value={linkedinAdSetup?.items?.[0].source}
                        validators={inputValidators.source}
                    />
                </AdSetupInputWrapper>
            </div>
        </Accordion>
    );
};

export default withResources(SingleFrame, ['linkedin_cta']);
